.spreadsheet-container {
  padding: 16px;
  font-family: Arial, sans-serif;
}

.react-spreadsheet__row:nth-child(1) .react-spreadsheet__cell {
  background-color: #e0e0e0;
  font-weight: bold;
  text-align: center;
  border: 1px solid #ddd;
}

.react-spreadsheet__cell {
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.header-row {
  font-weight: bold;
  color: blue;
}

.text-danger {
  color: red;
  width: 100%;
}

.whiteonblack {
  color: white;
  background-color: black;
}
.blackongold {
  color: black;
  background-color: gold;
}

.hidden-column {
  color: white;
  background-color: white;
}
.blackongrey {
  color: black;
  background-color: grey;
}
.app2 {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between; /* Added */
  background-color: #ffffff; /* white dropdown */
  border: 1px solid #1c0e9e; /* light gray border */

  padding: 15px; /* Added */
  padding-left: 20px;
}

.styled-button {
  background-color: white; /* Primary blue */
  color: black;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.styled-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slightly larger */
}

.styled-button:active {
  background-color: white; /* Even darker blue */
  transform: scale(1); /* Reset scale */
}

.save-button {
  background-color: white; /* Green for save */
}

.save-button:hover {
  background-color: #218838; /* Darker green */
}

.save-button:active {
  background-color: #19692c; /* Even darker green */
}
