.file-uploader-container {
  text-align: left; /* Align contents to the left */
  padding: 10px; /* Reduced padding */
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  max-width: 300px; /* Smaller width */
  margin: 10px; /* Adjust margin as needed */
}

.file-input-label {
  border: 1px solid #ccc;
  padding: 4px 8px; /* Reduced padding */
  cursor: pointer;
  margin-bottom: 5px; /* Smaller margin */
  border-radius: 4px;
  background: #f8f8f8;
  font-size: 0.8rem; /* Smaller font size */
}

.file-input {
  display: none;
}

.file-info {
  background-color: #e9e9e9;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 5px; /* Reduced padding */
  margin-top: 5px; /* Smaller margin */
  font-size: 0.8rem; /* Smaller font size */
  display: block; /* Ensure it takes up the full width for alignment */
}
.table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
}

.column-selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.column-selector label {
  margin-bottom: 5px;
  font-weight: bold;
}

.column-selector select {
  width: 200px; /* Adjust width as needed */
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Responsive adjustment */
@media (max-width: 768px) {
  .column-selector select {
    width: 150px; /* Smaller width on smaller screens */
  }
}
/* Button.css */
.pretty-button {
  background-color: white; /* Green background */
  border: none;
  color: black;
  padding: 1px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  height: 25px; /* Match the height of the select dropdown */
  margin-left: 20px; /* 20px space to the right */
}

.pretty-button:hover {
  background-color: #45a049; /* Darker shade of green */
}
