.plaid-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;  // Moves the button down (~5 line spaces)
}

.plaid-button {
  background: rgb(67, 149, 243);
  color: black;
  padding: 10px 30px;
  border: 1px solid #ddd; // Subtle border
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: none;

  &:hover {
    background: #f8f8f8; // Slight color change on hover
  }

  &:disabled {
    color: #999;
    border-color: #ccc;
    cursor: not-allowed;
    background: #f2f2f2;
  }
}
