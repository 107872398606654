.app {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between; /* Added */
  background-color: #ffffff; /* white dropdown */
  border: 1px solid #9e0e0e; /* light gray border */
  border-radius: 10px; /* rounded corners */
  padding: 20px; /* Added */
}

.app2 {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between; /* Added */
  background-color: #ffffff; /* white dropdown */
  border: 1px solid #1c0e9e; /* light gray border */

  padding: 15px; /* Added */
  padding-left: 20px;
}

.dropdown {
  margin-bottom: 20px;
  margin: 20px auto;
  align-items: center;
  //width: 400px; /* Adjust width as needed */
  display: flex; /* Added */
  justify-content: space-between; /* Added */
  background-color: #ffffff; /* white dropdown */
  border: 1px solid #9e0e0e; /* light gray border */
  border-radius: 10px; /* rounded corners */
  padding: 20px; /* Added */
}
#scheduler .dx-scheduler-cell-sizes-vertical {
  height: 50px !important;
}
.responsive-paddingsx {
  padding: 10px;

  .screen-large & {
    padding: 10px;
  }
  .red-color {
    color: red;
  }
}
.red-color {
  color: red;
}
.my-top-section {
  position: sticky;
  top: 0;
  background-color: rgb(169, 170, 178);
  color: white;
  z-index: 2; /* Set a z-index value higher than other content */
  padding: 10px; /* Add padding if needed */
}
.white-text-selectbox .dx-selectbox-content {
  color: white;
}
// .options {
//   padding: 20px;
//   background-color: rgba(191, 191, 191, 0.15);
//   margin-top: 20px;
// }

// .caption {
//   font-size: 18px;
//   font-weight: 500;
// }

// .option {
//   margin-top: 10px;
//   display: inline-block;
// }

// .dx-scheduler-work-space-group-by-date .dx-scheduler-group-header-content {
//   font-size: 20px;
// }

//////////////////////////////////////////////////
// .scheduler {
//   //height: 800px;
//   width: 1000px;
// }

// .scheduler {
//   width: 90%;
//   //max-width: 800px;
//   background-color: #ffffff; /* white scheduler */
//   border: 1px solid #d3d3d3; /* light gray border */
//   border-radius: 0px; /* rounded corners */
// }

// .dx-dropdowneditor-input-wrapper {
//   padding: 10px;
// }

// .dx-scrollview-content .dx-list-item {
//   display: flex;
//   justify-content: center;
//   padding: 10px 0;
//   cursor: pointer;
//   color: #000000; /* black text */
// }

// .dx-scrollview-content .dx-list-item:hover {
//   background-color: #87cefa; /* light blue hover */
//   color: #ffffff; /* white text */
// }
// .service-select-container {
//   width: 300px;
// }

// .service-select {
//   width: 100%;
// }
// #scheduler .dx-scheduler-appointment {
//   max-height: 200px;
// }
