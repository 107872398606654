.dx-datagrid .dx-row {
  font-size: 8px !important; /* Enforce smaller font size */
  line-height: 20px !important; /* Adjust line height */
  padding: 5px 10px !important; /* Adjust padding */
}

.dx-datagrid .dx-header-row {
  font-size: 6px !important; /* Smaller header font size */
  height: 30px !important; /* Adjust header row height */
}
