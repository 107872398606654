.custom-container .dx-datagrid {
  padding-left: 20px;
}
.yellow-background {
  background-color: yellow;
}
.right-aligned-editor .dx-texteditor-input {
  text-align: right;
}

.react-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.react-modal-header {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.react-modal-content {
  margin-bottom: 20px;
}

.react-modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.react-modal-button-confirm {
  background-color: #d9534f;
  color: white;
}

.react-modal-button-confirm:hover {
  background-color: #c9302c;
}

.react-modal-button-cancel {
  background-color: #337ab7;
  color: white;
}

.react-modal-button-cancel:hover {
  background-color: #286090;
}

.close-button {
  cursor: pointer;
  float: right;
  padding: 5px;
}

.high-z-index {
  z-index: 5050; /* Or a higher value as needed */
}
.red-color {
  color: red;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-container1 .dx-datagrid {
  padding-left: 50px;
}

.custom-container3 .dx-datagrid {
  padding-left: 500px;
}
body {
  margin: 0;
}

.custom-progress-container4 .dx-datagrid {
  padding-left: 600px;
  padding-right: 600px;
}
.custom-debtsummary-container4 .dx-datagrid {
  padding-left: 520px;
  padding-right: 520px;
}
.custom-container5 .dx-datagrid {
  padding-left: 550px;
  padding-right: 50px;
}

.custom-networth-container4 .dx-datagrid {
  padding-left: 550px;
  padding-right: 550px;
}

.content-combined-block {
  padding-left: 1px;
  padding-right: 1px;
}
.root {
  background-color: black;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 7vh;
  color: white;
  text-align: center;
  padding-left: 50px;
  padding-top: 3px;
}

.root h1 {
  color: white;
  font-weight: bold;
  font-size: 3rem;
}

.small-font {
  font-size: 4px;
}
.dx-field-item-label {
  font-weight: bold;
}
.high-z-index-popup {
  z-index: 1050 !important; /* Ensure the z-index is higher than the form */
}
.custom-data-grid .dx-datagrid,
.custom-data-grid .dx-datagrid-rowsview,
.custom-data-grid .dx-datagrid-header-panel {
  font-size: 10px;
  //color: #c9302c; /* Adjust the font size as needed */
}
